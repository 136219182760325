import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

import { ButtonSizeDirective } from '@tk/shared/itera-ui-kit/cdk/button-size';
import { KeyboardFocusMonitorDirective } from '@tk/shared/itera-ui-kit/cdk/focus';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tk-close-button]',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    KeyboardFocusMonitorDirective,
    {
      directive: ButtonSizeDirective,
      inputs: ['size'],
    },
  ],
})
export class TkCloseButtonComponent {
  @HostBinding('attr.data-variant')
  @Input()
  variant: 'primary' | 'inverse' | 'accent' = 'primary';

  @HostBinding('attr.aria-label')
  @Input()
  label = 'close';
}
